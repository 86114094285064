import "@reach/dialog/styles.css";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";
import Loader from "react-feather/dist/icons/loader";

import {joinList} from "../../utils/joinList";
import SanityRichText from "../commons/SanityRichText";
import {MasonIcon} from "../icons/Mason";
import {MasonTextIcon} from "../icons/MasonText";
import "./styles.scss";

export const EbookModal = ({data = {}}) => {
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({});

  const {
    _rawAboveCta,
    _rawDescription,
    _rawThanksDecription,
    alt = "",
    companyPlaceholder = "Your company name",
    cta = "Download Now",
    descriptionHeading,
    ebook: {asset: {url} = {}} = {},
    emailPlaceholder = "Your email",
    formSubTitle,
    formTitle,
    image: {asset: {gatsbyImageData} = {}} = {},
    leftTitle,
    mailchimpTagName,
    namePlaceholder = "Your Name",
    thankYouAlt = "",
    thanksCta,
    thanksCtaUrl,
    thanksImage: {asset: {gatsbyImageData: thanksImage} = {}} = {},
    thanksSubTitle,
    thanksTitle,
  } = data;

  const [showThanksPage, setShowThanksPage] = React.useState(false);

  const handleChange = (e) => {
    setState((state) => ({...state, [e.target.name]: e.target.value}));
  };

  const join = () => {
    const {name, email, company} = state;
    setLoading(true);
    joinList({name, email, company, mailchimpTagName})
      .then(() => {
        setShowThanksPage(true);
        let newWindow = window.open(url, "_blank");
        newWindow.opener = null;
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const formRef = React.useRef();

  const onSubmit = (e) => {
    e.preventDefault();

    if (formRef?.current && formRef.current.reportValidity()) {
      join();
    }
  };

  return (
    <div className='ebookModal'>
      <div className='left'>
        <h1>{leftTitle}</h1>
        <div className='heroImageContainer'>
          <GatsbyImage image={gatsbyImageData} alt={alt || leftTitle} />
        </div>
        <h3>{descriptionHeading}</h3>
        <SanityRichText
          blocks={_rawDescription}
          className='heroDescription'
          renderContainerOnSingleChild
        />
      </div>
      <div className='right'>
        <div>
          <h3>{showThanksPage ? thanksTitle : formTitle}</h3>
          <p>{showThanksPage ? thanksSubTitle : formSubTitle}</p>
          {showThanksPage ? (
            <div className='thankYouImageContainer'>
              <GatsbyImage
                image={thanksImage}
                alt={thankYouAlt || thanksTitle}
              />
            </div>
          ) : (
            <form
              className='form'
              onSubmit={(e) => {
                e.preventDefault();
              }}
              ref={formRef}
            >
              <div className='formElement'>
                <input
                  onChange={handleChange}
                  className='input'
                  placeholder={namePlaceholder}
                  name='name'
                  type='string'
                  required
                />
              </div>
              <div className='formElement'>
                <input
                  onChange={handleChange}
                  className='input'
                  placeholder={emailPlaceholder}
                  name='email'
                  // In case we want a custom invalid message
                  // onInvalid={(e) => {
                  //   e.target.setCustomValidity(
                  //     "Please enter a valid email address"
                  //   );
                  // }}
                  // onInput={(e) => {
                  //   e.target.setCustomValidity("");
                  // }}
                  title='Please enter a valid email address'
                  pattern='^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(?!hotmail|gmail|yahoo)(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$'
                  type='email'
                  required
                />
              </div>
              <div className='formElement'>
                <input
                  onChange={handleChange}
                  className='input'
                  placeholder={companyPlaceholder}
                  name='company'
                  type='string'
                />
              </div>
            </form>
          )}
          <SanityRichText
            blocks={showThanksPage ? _rawThanksDecription : _rawAboveCta}
            className='aboveCta'
            renderContainerOnSingleChild
          />
        </div>
        <div>
          {showThanksPage ? (
            <OutboundLink
              href={thanksCtaUrl}
              rel='noopener noreferrer'
              target='_blank'
              className='formCta'
              id='add-mm-app'
            >
              {thanksCta}
            </OutboundLink>
          ) : (
            <button className='formCta' type='button' onClick={onSubmit}>
              {loading ? (
                <>
                  Downloading... <Loader width={24} className='loader' />
                </>
              ) : (
                cta
              )}
            </button>
          )}
          <div className='poweredBy'>
            <span>Powered by </span>
            <MasonIcon />
            <MasonTextIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
