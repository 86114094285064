import axios from "axios";
import "regenerator-runtime/runtime";

export const joinList = async (data) => {
  await axios.post("/api/notifySlack", {
    ...data,
    env: process.env.GATSBY_ENV,
  });
  await axios.post("/api/subscribeEbook", {
    ...data,
  });
};
