import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import {EbookModal} from "../../components/ebook/ebookModal";

const Ebook = ({data, pageContext: {data: ebook = {}} = {}}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];

  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: ebook?.leftTitle,
          items: [
            {
              name: ebook?.leftTitle,
              item: `https://getmason.io/${ebook?.slug?.current}`,
            },
          ],
        }}
      />
      <Buffer buffer={81} mobileBuffer={30} />
      <section className='full-bleed'>
        <EbookModal data={ebook} />
      </section>
      <LastSection hideTicketSection />
    </Layout>
  );
};

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default Ebook;
