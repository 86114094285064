import * as React from "react";

export const MasonTextIcon = ({className, onClick}) => {
  return (
    <svg
      width='54'
      height='10'
      viewBox='0 0 54 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M1.28664 9.45739C1.16174 9.45739 1.05955 9.4187 0.980064 9.34132C0.900581 9.26393 0.86084 9.16444 0.86084 9.04284V1.24948C0.86084 1.12789 0.900581 1.0284 0.980064 0.951016C1.05955 0.873635 1.16174 0.834944 1.28664 0.834944H3.14313C3.26803 0.834944 3.37023 0.873635 3.44971 0.951016C3.52919 1.0284 3.56893 1.12789 3.56893 1.24948V1.81326C3.83009 1.48163 4.17641 1.21079 4.60789 1.00076C5.05072 0.779672 5.54465 0.669128 6.08967 0.669128C7.33869 0.669128 8.20165 1.14447 8.67854 2.09515C8.95106 1.67508 9.33144 1.33239 9.81969 1.06709C10.3079 0.801781 10.8303 0.669128 11.3866 0.669128C12.3177 0.669128 13.0728 0.978652 13.6519 1.5977C14.2423 2.20569 14.5376 3.11768 14.5376 4.33367V9.04284C14.5376 9.15339 14.4978 9.25288 14.4183 9.34132C14.3389 9.4187 14.2367 9.45739 14.1118 9.45739H12.136C12.0111 9.45739 11.9033 9.4187 11.8124 9.34132C11.733 9.26393 11.6932 9.16444 11.6932 9.04284V4.44974C11.6932 3.89702 11.574 3.49906 11.3355 3.25586C11.1084 3.01266 10.7962 2.89107 10.3988 2.89107C10.0354 2.89107 9.73453 3.01819 9.49608 3.27244C9.25763 3.51564 9.13841 3.90807 9.13841 4.44974V9.04284C9.13841 9.15339 9.09867 9.25288 9.01919 9.34132C8.9397 9.4187 8.83751 9.45739 8.71261 9.45739H6.73689C6.61199 9.45739 6.5098 9.4187 6.43032 9.34132C6.35083 9.26393 6.31109 9.16444 6.31109 9.04284V4.44974C6.31109 3.90807 6.18619 3.51564 5.93639 3.27244C5.69794 3.01819 5.39136 2.89107 5.01666 2.89107C4.65331 2.89107 4.34673 3.01819 4.09693 3.27244C3.85848 3.51564 3.73925 3.90254 3.73925 4.43316V9.04284C3.73925 9.15339 3.69951 9.25288 3.62003 9.34132C3.54055 9.4187 3.43835 9.45739 3.31345 9.45739H1.28664Z'
        fill='#02152D'
      />
      <path
        d='M18.7788 9.6232C18.177 9.6232 17.6263 9.51266 17.1267 9.29157C16.6384 9.05943 16.2524 8.7499 15.9685 8.363C15.696 7.96504 15.5598 7.52839 15.5598 7.05305C15.5598 6.2903 15.8777 5.67678 16.5135 5.21249C17.1608 4.74821 18.0464 4.42763 19.1705 4.25076L21.2995 3.93571V3.70356C21.2995 3.2835 21.2087 2.97397 21.027 2.77499C20.8454 2.57601 20.5388 2.47652 20.1073 2.47652C19.8461 2.47652 19.6304 2.52074 19.4601 2.60918C19.2898 2.69761 19.1138 2.81921 18.9321 2.97397C18.7731 3.10663 18.6539 3.19506 18.5744 3.23928C18.5404 3.32771 18.4779 3.37193 18.3871 3.37193H16.5306C16.417 3.37193 16.3205 3.33877 16.241 3.27244C16.1729 3.19506 16.1445 3.10663 16.1559 3.00714C16.1672 2.70867 16.3148 2.37704 16.5987 2.01224C16.8939 1.64744 17.3368 1.33239 17.9272 1.06709C18.529 0.801781 19.2671 0.669128 20.1414 0.669128C21.538 0.669128 22.5713 0.973125 23.2412 1.58112C23.9111 2.17806 24.2461 2.98503 24.2461 4.00203V9.04284C24.2461 9.15339 24.2063 9.25288 24.1269 9.34132C24.0474 9.4187 23.9452 9.45739 23.8203 9.45739H21.8446C21.731 9.45739 21.6288 9.4187 21.538 9.34132C21.4585 9.25288 21.4188 9.15339 21.4188 9.04284V8.46249C21.169 8.80517 20.817 9.08706 20.3628 9.30815C19.92 9.51818 19.392 9.6232 18.7788 9.6232ZM19.5793 7.7329C20.1016 7.7329 20.5218 7.56708 20.8397 7.23545C21.169 6.90382 21.3336 6.41742 21.3336 5.77627V5.54412L19.8859 5.79285C18.864 5.96972 18.353 6.31793 18.353 6.83749C18.353 7.11385 18.4722 7.33494 18.7107 7.50075C18.9491 7.65552 19.2387 7.7329 19.5793 7.7329Z'
        fill='#02152D'
      />
      <path
        d='M29.4723 9.6232C28.5525 9.6232 27.7804 9.49608 27.1559 9.24183C26.5428 8.98757 26.0886 8.68358 25.7933 8.32984C25.4981 7.97609 25.3505 7.66104 25.3505 7.38468C25.3505 7.27414 25.3903 7.1857 25.4697 7.11938C25.5606 7.042 25.6571 7.00331 25.7593 7.00331H27.7009C27.7691 7.00331 27.8315 7.03094 27.8883 7.08621C28.0245 7.17465 28.1267 7.2465 28.1949 7.30178C28.4447 7.47865 28.6604 7.6113 28.8421 7.69973C29.0351 7.77711 29.2679 7.81581 29.5404 7.81581C29.8697 7.81581 30.1365 7.75501 30.3409 7.63341C30.5566 7.50075 30.6645 7.31836 30.6645 7.08621C30.6645 6.89829 30.6077 6.74906 30.4942 6.63851C30.392 6.52797 30.1876 6.42295 29.881 6.32346C29.5745 6.21292 29.1089 6.09684 28.4844 5.97525C27.542 5.78732 26.8153 5.48333 26.3043 5.06326C25.8047 4.63214 25.5549 4.05731 25.5549 3.33877C25.5549 2.88554 25.7025 2.45442 25.9977 2.0454C26.293 1.63639 26.7301 1.30476 27.3092 1.05051C27.8996 0.796254 28.598 0.669128 29.4041 0.669128C30.2103 0.669128 30.9143 0.790727 31.5161 1.03392C32.1179 1.27712 32.5721 1.57559 32.8787 1.92933C33.1966 2.27202 33.3556 2.58707 33.3556 2.87448C33.3556 2.97397 33.3158 3.06241 33.2363 3.13979C33.1682 3.21717 33.0831 3.25586 32.9809 3.25586H31.2095C31.1187 3.25586 31.0335 3.22822 30.9541 3.17295C30.8064 3.09557 30.6759 3.01266 30.5623 2.92423C30.3693 2.78052 30.1876 2.66998 30.0173 2.5926C29.847 2.51521 29.6369 2.47652 29.3871 2.47652C29.0919 2.47652 28.8534 2.54285 28.6718 2.6755C28.5014 2.80816 28.4163 2.9795 28.4163 3.18953C28.4163 3.35535 28.4617 3.49353 28.5525 3.60407C28.6547 3.71462 28.8591 3.82516 29.1657 3.93571C29.4836 4.0352 29.9492 4.14021 30.5623 4.25076C31.6637 4.43868 32.4642 4.77032 32.9638 5.24566C33.4748 5.72099 33.7303 6.27924 33.7303 6.9204C33.7303 7.73843 33.3556 8.39616 32.6062 8.89361C31.8567 9.38 30.8121 9.6232 29.4723 9.6232Z'
        fill='#02152D'
      />
      <path
        d='M39.0908 9.6232C37.6829 9.6232 36.5985 9.2971 35.8377 8.64489C35.077 7.99268 34.6625 7.07516 34.5944 5.89234C34.583 5.74863 34.5773 5.49991 34.5773 5.14616C34.5773 4.79242 34.583 4.5437 34.5944 4.39999C34.6625 3.22822 35.0883 2.31624 35.8718 1.66403C36.6553 1.00076 37.7283 0.669128 39.0908 0.669128C40.4648 0.669128 41.5435 1.00076 42.3269 1.66403C43.1104 2.31624 43.5362 3.22822 43.6043 4.39999C43.6157 4.5437 43.6214 4.79242 43.6214 5.14616C43.6214 5.49991 43.6157 5.74863 43.6043 5.89234C43.5362 7.07516 43.1218 7.99268 42.361 8.64489C41.6002 9.2971 40.5102 9.6232 39.0908 9.6232ZM39.0908 7.60024C39.5904 7.60024 39.9595 7.45654 40.1979 7.16912C40.4364 6.87065 40.5726 6.41742 40.6067 5.80943C40.618 5.69889 40.6237 5.4778 40.6237 5.14616C40.6237 4.81453 40.618 4.59344 40.6067 4.4829C40.5726 3.88596 40.4307 3.43826 40.1809 3.13979C39.9424 2.84132 39.5791 2.69209 39.0908 2.69209C38.1484 2.69209 37.6488 3.28902 37.592 4.4829L37.575 5.14616L37.592 5.80943C37.6147 6.41742 37.7453 6.87065 37.9838 7.16912C38.2336 7.45654 38.6026 7.60024 39.0908 7.60024Z'
        fill='#02152D'
      />
      <path
        d='M45.3983 9.45739C45.2734 9.45739 45.1712 9.4187 45.0917 9.34132C45.0122 9.26393 44.9725 9.16444 44.9725 9.04284V1.24948C44.9725 1.12789 45.0122 1.0284 45.0917 0.951016C45.1712 0.873635 45.2734 0.834944 45.3983 0.834944H47.357C47.4819 0.834944 47.5841 0.873635 47.6635 0.951016C47.7544 1.0284 47.7998 1.12789 47.7998 1.24948V1.87959C48.1064 1.51479 48.4981 1.22185 48.975 1.00076C49.4633 0.779672 50.014 0.669128 50.6271 0.669128C51.6263 0.669128 52.4382 1.00076 53.0627 1.66403C53.6872 2.31624 53.9995 3.23375 53.9995 4.41657V9.04284C53.9995 9.15339 53.9597 9.25288 53.8802 9.34132C53.8008 9.4187 53.6986 9.45739 53.5737 9.45739H51.4276C51.3141 9.45739 51.2119 9.4187 51.121 9.34132C51.0416 9.25288 51.0018 9.15339 51.0018 9.04284V4.51606C51.0018 3.99651 50.8712 3.59855 50.6101 3.32219C50.3489 3.03477 49.9742 2.89107 49.486 2.89107C49.0091 2.89107 48.6344 3.03477 48.3619 3.32219C48.0893 3.59855 47.9531 3.99651 47.9531 4.51606V9.04284C47.9531 9.15339 47.9133 9.25288 47.8339 9.34132C47.7544 9.4187 47.6522 9.45739 47.5273 9.45739H45.3983Z'
        fill='#02152D'
      />
    </svg>
  );
};
